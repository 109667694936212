var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"light":""}},[_c('v-toolbar-title',[_vm._v(" Gestión de Documentos ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"hide-details":"","single-line":"","clearable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscarBtn()}},model:{value:(_vm.searchTxt),callback:function ($$v) {_vm.searchTxt=$$v},expression:"searchTxt"}}),_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.buscarBtn()}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.sincronizar()}}},[_vm._v("Sincronizar FTP "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-folder-sync")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":"/main/admin/documentos/create"}},[_vm._v("Crear Documento")])],1),(_vm.allDocuments.length >= 1)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allDocuments},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.nombre))]),_c('td',[_vm._v(_vm._s(props.item.descripcion))]),_c('td',[_c('v-btn',{attrs:{"color":"primary","depressed":"","icon":"","href":_vm.url(props.item.url),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-file-"+_vm._s(props.item.tipo.tipo_ico))])],1)],1),_c('td',[_vm._v(_vm._s(props.item.categoria.categoria))]),_c('td',{staticClass:"justify-center layout px-0 align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","to":{
                name: 'main-admin-documentos-edit',
                params: { id: props.item.id },
              }}},on),[_c('v-icon',[_vm._v("edit")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true),model:{value:(_vm.alertMsg),callback:function ($$v) {_vm.alertMsg=$$v},expression:"alertMsg"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Borrar documento ")]),_c('v-card-text',[_vm._v(" ¿Estás seguro de que quieres borrar este documento?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":function($event){_vm.alertMsg = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.borrarDocumento(props.item.id)}}},[_vm._v(" Sí ")])],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)])]}}],null,false,2624196440)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }