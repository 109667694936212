






































































import { Component, Vue } from 'vue-property-decorator';
import { readAdminDocuments } from '@/store/admin/getters';
import {
  dispatchGetDocumentos,
  dispatchDeleteDocumento,
  dispatchSincronizar,
} from '@/store/admin/actions';
import { apiUploadFolder } from '@/env';
import { api } from '@/api';
import { getLocalToken } from '@/utils';
@Component
export default class AdminDocumentos extends Vue {
  public alertMsg = false;
  public searchTxt = '';
  public allDocuments = [] as any;

  public headers = [
    {
      text: 'Nombre',
      sortable: true,
      value: 'nombre',
      align: 'left',
    },
    {
      text: 'Descripción',
      sortable: true,
      value: 'descripcion',
      align: 'left',
    },

    {
      text: 'Archivo',
      sortable: true,
      value: 'url',
      align: 'left',
    },
    {
      text: 'Categoría',
      sortable: true,
      value: 'categoria',
      align: 'left',
    },
    {
      text: 'Acciones',
      align: 'center',
      value: 'id',
    },
  ];

  public url(u) {
    return apiUploadFolder + u;
  }
  // get documentos() {
  //   const docs = readAdminDocuments(this.$store);
  //   return docs;
  // }

  public async mounted() {
    // await dispatchGetDocumentos(this.$store);
    // this.allDocuments = this.documentos;
  }
  public async borrarDocumento(id) {
    await dispatchDeleteDocumento(this.$store, id);
    this.alertMsg = false;
    await dispatchGetDocumentos(this.$store);
  }
  public async sincronizar() {
    await dispatchSincronizar(this.$store);
    this.alertMsg = false;
    await dispatchGetDocumentos(this.$store);
  }
  // public buscar() {
  //   if (this.searchTxt.length > 0) {
  //     let alldocs = [] as any;
  //     alldocs = this.documentos;
  //     const filtro = alldocs.filter((doc) => {
  //       return (
  //         this.stripVowelAccent(doc.nombre.toLowerCase()).indexOf(
  //           this.stripVowelAccent(this.searchTxt.toLowerCase()),
  //         ) !== -1 ||
  //         this.stripVowelAccent(doc.descripcion.toLowerCase()).indexOf(
  //           this.stripVowelAccent(this.searchTxt.toLowerCase()),
  //         ) !== -1
  //       );
  //     });
  //     this.allDocuments = filtro;
  //   } else {
  //     this.allDocuments = this.documentos;
  //   }
  // }
  public async buscarBtn() {
    const token = getLocalToken()!;
    const response = await api.getDocumentsBuscar(
      token, this.searchTxt
    );
    this.allDocuments = response.data;
    // console.log(response);
    //   let alldocs = [] as any;
    //   alldocs = this.documentos;
    //   const filtro = alldocs.filter((doc) => {
    //     return (
    //       this.stripVowelAccent(doc.nombre.toLowerCase()).indexOf(
    //         this.stripVowelAccent(this.searchTxt.toLowerCase()),
    //       ) !== -1 ||
    //       this.stripVowelAccent(doc.descripcion.toLowerCase()).indexOf(
    //         this.stripVowelAccent(this.searchTxt.toLowerCase()),
    //       ) !== -1
    //     );
    //   });
    //   this.allDocuments = filtro;
    // } else {
    //   this.allDocuments = this.documentos;
    // }
  }
  public stripVowelAccent(str) {
    const rExps = [
      { re: /[\xC0-\xC6]/g, ch: 'A' },
      { re: /[\xE0-\xE6]/g, ch: 'a' },
      { re: /[\xC8-\xCB]/g, ch: 'E' },
      { re: /[\xE8-\xEB]/g, ch: 'e' },
      { re: /[\xCC-\xCF]/g, ch: 'I' },
      { re: /[\xEC-\xEF]/g, ch: 'i' },
      { re: /[\xD2-\xD6]/g, ch: 'O' },
      { re: /[\xF2-\xF6]/g, ch: 'o' },
      { re: /[\xD9-\xDC]/g, ch: 'U' },
      { re: /[\xF9-\xFC]/g, ch: 'u' },
      { re: /[\xD1]/g, ch: 'N' },
      { re: /[\xF1]/g, ch: 'n' },
    ];

    for (let i = 0, len = rExps.length; i < len; i++) {
      str = str.replace(rExps[i].re, rExps[i].ch);
    }
    return str;
  }
}
